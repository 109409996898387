import React from "react";

import { graphql } from "gatsby";

import { Feed } from "@/components/Feed";
import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Pagination } from "@/components/Pagination";
import { Sidebar } from "@/components/Sidebar";
import { useSiteMetadata } from "@/hooks";
import { AllMarkdownRemark, PageContext } from "@/types";
import { formattedDayString, getPostsExcludeFuturePosts } from "@/utils";

interface Props {
  data: {
    allMarkdownRemark: AllMarkdownRemark;
  };
  pageContext: PageContext;
}

const PostedMonthTemplate: React.FC<Props> = ({ data, pageContext }: Props) => {
  const { group, pagination } = pageContext;
  const { prevPagePath, nextPagePath, hasPrevPage, hasNextPage } = pagination;
  const { edges } = data.allMarkdownRemark;

  const postsExcludeFuturePosts = getPostsExcludeFuturePosts(edges);

  return (
    <Layout>
      <Sidebar />
      <Page
        title={formattedDayString.getFormattedPostedMonthString({
          month: group,
        })}
      >
        <Feed edges={postsExcludeFuturePosts} />
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query PostedMonthTemplate(
    $limit: Int!
    $offset: Int!
    $dateUpperLimit: Float
    $dateLowerLimit: Float
  ) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allMarkdownRemark(
      limit: $limit
      skip: $offset
      filter: {
        frontmatter: {
          template: { eq: "post" }
          draft: { ne: true }
          date: { lte: $dateUpperLimit, gte: $dateLowerLimit }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
            postedMonthSlug
          }
          frontmatter {
            title
            date
            category
            description
            slug
          }
        }
      }
    }
  }
`;

export const Head: React.FC<Props> = ({ pageContext }) => {
  const { title, subtitle } = useSiteMetadata();

  const {
    group,
    pagination: { currentPage: page },
  } = pageContext;

  const pageTitle =
    page > 0
      ? `${formattedDayString.getFormattedPostedMonthString({
          month: group,
        })} - Page ${page} - ${title}`
      : `${formattedDayString.getFormattedPostedMonthString({
          month: group,
        })} - ${title}`;

  return <Meta title={pageTitle} description={subtitle} />;
};

export default PostedMonthTemplate;
